import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'

import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'

export default ({ message = 'Strona nie znaleziona', title = '404' }) => {
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 2500)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <ThemeProvider>
      <Layout>
        <ContentWrapper>
          {isLoading && <CircularProgressStyled />}
          {!isLoading && (
            <>
              <h1>{title}</h1>
              <p>{message}</p>
            </>
          )}
        </ContentWrapper>
      </Layout>
    </ThemeProvider>
  )
}

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 60vh;
  overflow: hidden;
`

const CircularProgressStyled = styled(CircularProgress)`
  width: 80px !important;
  height: 80px !important;
  vertical-align: middle;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.apple.hex()} !important;
`
